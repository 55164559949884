import React, { useCallback, useContext, useState, useEffect } from "react";

import {
  Box,
  Image,
  LanguageContext,
  AddToCart,
  Link,
  Divider
} from "@eriksdigital/atomic-ui/components";
import { NoPriceMessage } from "./styles";
import { Price } from "../Price";
import { GTMContext } from "@eriksdigital/gs-ga4";

import {
  Wrapper,
  Title,
  EriksNumber,
  TextWrapper,
  MainBlock,
  PriceBlock
} from "./styles";
import { NotificationContext } from "../../providers/NotificationProvider";
import { BREAKPOINTS_LABELS, useMedia } from "../../utils/useMedia";
import { Item as SingleItem } from "../../types";
import { StockInformation } from "../StockInformation";
import debounce from "lodash/debounce";
import { FormattedMessage, useIntl } from "react-intl";
import { useRaffaella, useClearResource } from "@eriksdigital/raffaella";
import { ItemQuantityContext } from "providers/ItemQuantity";
import { languageToCode } from "utils/languageUtils";

interface Props {
  // TODO change to generated type
  data: SingleItem;
  type?: string;
  index: number;
}

export const Item = (props: Props) => {
  const { data, type, index, ...rest } = props;
  const { sendAddToCartEvent } = useContext<any>(GTMContext);
  const { erpSystem, language } = useContext<any>(LanguageContext);
  const { createNotification } = useContext<any>(NotificationContext);
  const isTablet = useMedia(BREAKPOINTS_LABELS.tabletLandscape);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const TIMEOUT = 1000;
  const intl = useIntl();
  const [user] = useRaffaella("user");
  const [price] = useRaffaella("price");
  const clearAddToCart = useClearResource("addToCart");
  const [addToCart, makeResourceCallAddToCart] = useRaffaella("addToCart");
  const [, makeResourceCallShopingCart] = useRaffaella("shoppingCart");
  const { isGuestUser, loginUrl, loggedIn } = user.data;
  const {
    updateQuantity,
    state: { quantity: itemQuantity }
  } = useContext<any>(ItemQuantityContext);
  //TOOD: USE RAW PRICE INSTEAD this is quick fix
  const rawPrice = data.priceData && data.priceData.rawPrice;

  const handleAddToCartSuccess = useCallback(() => {
    createNotification &&
      createNotification(
        {
          id: `${Date.now()}-${data.erpPartNumber}`,
          variant: "success",
          message: (
            <FormattedMessage
              id="add-to-cart.success"
              defaultMessage="The product was added to your shopping cart"
              description="Label of add to cart successful"
            />
          ),
          autoDismiss: true
        },
        3000
      );

    clearAddToCart();
  }, [clearAddToCart, createNotification, data.erpPartNumber]);

  const handleAddToCartError = useCallback(() => {
    createNotification &&
      createNotification(
        {
          id: `${Date.now()}-${data.erpPartNumber}`,
          variant: "error",
          message: (
            <FormattedMessage
              id="add-to-cart.error"
              defaultMessage="Our apologies! Something went wrong when adding the product to your shopping cart. Please contact the ERIKS support team."
              description="Label of add to cart when there is an error "
            />
          ),
          autoDismiss: true
        },
        3000
      );

    window.location.href = loginUrl;
  }, [createNotification, data.erpPartNumber, loginUrl]);

  useEffect(() => {
    if (
      !addToCart.isLoading &&
      addToCart.data !== null &&
      addToCart.error === null
    ) {
      setAddToCartLoading(false);

      makeResourceCallShopingCart &&
        makeResourceCallShopingCart({
          apiKey: "shoppingCart",
          reqData: {
            erpSystem: erpSystem.toUpperCase(),
            locale: languageToCode(language),
            ...(erpSystem === "ch" && {
              reconfigure: true
            }),
            targetSystem: "SEL_PLA"
          }
        });
    }
  }, [addToCart, erpSystem, language, makeResourceCallShopingCart]);

  useEffect(() => {
    if (
      !addToCart.isLoading === true &&
      addToCart.data === null &&
      !!addToCart.error === true
    ) {
      handleAddToCartError();
      setAddToCartLoading(false);
    }
  }, [
    addToCart.isLoading,
    addToCart.error,
    addToCart.data,
    handleAddToCartError
  ]);

  const sendToCart = () => {
    makeResourceCallAddToCart &&
      makeResourceCallAddToCart({
        apiKey: "addToCart",
        reqData: {
          erpSystem: erpSystem.toUpperCase(),
          lang: language,
          ...(erpSystem === "ch" && {
            reconfigure: true
          }),
          quantity: itemQuantity,
          erpPartNumber: data.erpPartNumber,
          length: data.length,
          ...(data.width && { width: data.width }),
          targetSystem: "SEL_PLA"
        }
      });
  };

  const handleAddToCart = async () => {
    const {
      brand,
      partNumber,
      erpPartNumber,
      englishDescription,
      priceData,
      itemStock
    } = data;
    sendAddToCartEvent &&
      sendAddToCartEvent({
        itemId: partNumber,
        price: (priceData && priceData.netValue) || "",
        itemBrand: brand || "",
        itemStockStatus: (itemStock && itemStock.available) || "",
        itemName: englishDescription,
        index: index + 1,
        quantity: itemQuantity,
        materialNumber: erpPartNumber
      });
    sendToCart();
    setAddToCartLoading(true);
  };

  useEffect(() => {
    if (
      addToCart.data !== null &&
      addToCart.error === null &&
      !addToCart.isLoading
    ) {
      handleAddToCartSuccess();
    }
  }, [addToCart, handleAddToCartSuccess]);

  const shouldShowPriceInformation = () => {
    if (erpSystem === "ch") {
      return !loggedIn;
    }

    return isGuestUser;
  };

  const handleQuantityChange = (quantity: number) => {
    if (quantity) {
      updateQuantity({ erpPartNumber: data.erpPartNumber, quantity });
    }
  };

  const debouncedEventHandler = debounce(handleQuantityChange, TIMEOUT);

  const stockInformation = () =>
    data.itemStock && <StockInformation data={data.itemStock} />;

  const priceInformation = () => {
    const shouldShowNoPrice =
      !price.isLoading && price.error && rawPrice === "0.00";
    if (shouldShowNoPrice) {
      return (
        <NoPriceMessage marginTop="auto" marginBottom="auto">
          <FormattedMessage
            id="results.noPriceMessage"
            defaultMessage="No price can be calculated for this item, please reach out to your contact person"
            description="Label of Not price in search results section"
          />
        </NoPriceMessage>
      );
    }

    const formPrice = data.priceData !== undefined ? data.priceData : null;

    return (
      <>
        <Price data={formPrice} />
        {isTablet && (
          <Box width="300px">
            <AddToCart
              id="button"
              quantityOnChange={debouncedEventHandler}
              quantityLabel={intl.formatMessage({
                id: "results.amount",
                defaultMessage: "Quantity",
                description: "label of Quantity in add to cart section"
              })}
              addLabel={intl.formatMessage({
                id: "add-to-cart.button",
                defaultMessage: "Add to cart",
                description: "label of Add to cart button"
              })}
              onAdd={handleAddToCart}
              quantityInitialValue={itemQuantity}
              disabled={price?.isLoading || rawPrice === "0.00"}
              addToCartLoading={addToCartLoading}
            />
          </Box>
        )}
      </>
    );
  };

  return (
    <Wrapper {...rest}>
      <MainBlock display="flex">
        <Box width="200px">
          <Image src={data.imageUrl} />
        </Box>
        <TextWrapper>
          <Title data-testid="description">{data.description}</Title>
          <EriksNumber data-testid="itemNumber">
            <FormattedMessage
              id="results.eriksNumber"
              defaultMessage="ERIKS Item: "
              description="Label of eriks item in search results "
            />
            {data.configuration}
          </EriksNumber>
          <EriksNumber data-testid="basicItemNumber">
            <FormattedMessage
              id="results.basicComponent"
              defaultMessage="Basic component: "
              description="Label of eriks component in search results "
            />{" "}
            {data.erpPartNumber}
          </EriksNumber>
          {isTablet && stockInformation()}
        </TextWrapper>
      </MainBlock>
      {!isTablet && (
        <Box width="100%" marginTop="sp16" marginBottom="sp16">
          <Divider />
        </Box>
      )}
      {shouldShowPriceInformation() ? (
        <PriceBlock marginTop="auto" marginBottom="auto">
          <Box>
            <Link
              data-testid="loginForPrices"
              alt="login for prices"
              href={loginUrl}
            >
              <FormattedMessage
                id="login.priceLogin"
                defaultMessage="Please login to see prices"
                description="Label of login message to se see prices"
              />
            </Link>
          </Box>
        </PriceBlock>
      ) : (
        priceInformation()
      )}
      {!isTablet && (
        <Box marginTop="sp8" marginBottom="sp8">
          {stockInformation()}
        </Box>
      )}

      {!isTablet && price && (!price.error || price.data !== null) && (
        <Box width="100%">
          <AddToCart
            id="button"
            quantityOnChange={debouncedEventHandler}
            quantityLabel={intl.formatMessage({
              id: "results.amount",
              defaultMessage: "Quantity",
              description: "label of Quantity in add to cart section"
            })}
            addLabel={intl.formatMessage({
              id: "add-to-cart.button",
              defaultMessage: "Add to cart",
              description: "label of Add to cart button"
            })}
            onAdd={handleAddToCart}
            quantityInitialValue={itemQuantity}
            disabled={price?.isLoading || rawPrice === "0.00"}
            addToCartLoading={addToCartLoading}
          />
        </Box>
      )}
    </Wrapper>
  );
};

export default Item;
